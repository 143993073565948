import { Helmet } from '@/components'
import { Input } from 'antd'
import { keyframes } from '@emotion/react'
import { Close4, Fanhuishangye } from '@imile/icons'
import { Box, Grid, useMediaQuery } from '@mui/material'
import { GlobalAntdFormStyle } from '@/components/GlobalCSS/AntdFormStyle'
import { useModalState } from '../../utils'
import { useTheme } from '@mui/material/styles'
import { graphql } from 'gatsby'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useEffect, useState, useMemo } from 'react'
import { useRequest } from 'ahooks'
import debounce from 'lodash/debounce'
import { Footer, Header, SEO, useI18next } from '@/components'
import { useLanguageByIp } from '@/hooks/useLanguageByIp'
import { Drawer, Modal, ModalProps } from 'antd'
import { Form, FormItem, FormItemProps } from '@/components/EnquireForm/Form'
import SelectModalH5 from '@/components/EnquireForm/SelectModalH5'
import { Button, Col, Row, Select, message } from 'antd'
import Cookies from 'js-cookie'
const devProxyWebsite = 'https://dev-express.52imile.cn'

const { Option } = Select

const ptBoxInFormItemsx = {
  height: '46px',
  bgcolor: '#f6f8fb',
  borderRadius: '6px',
  px: '16px',
  lineHeight: '46px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}

export const styleRoot = () => {
  return {
    p: ['1.8re, 0 1rem 0', '100px 0px 20px 0px'],
    display: 'flex',
    justifyContent: 'center',
    background: '#F6F7FA',
    flexDirection: 'column',
    '.pageTitle': {
      color: '#000417',
      fontSize: ['1rem', '20px'],
      fontWeight: '600',
      display: 'flex',
      justifyContent: ['left', 'center'],
      m: ['4rem 0 0 0', '20px 0'],
      '.line': {
        color: '#D7D9DF',
        m: '0 10px',
      },
    },
    '.pageBody': {
      width: ['100%', '1000px'],
      display: ['block', 'flex'],
      justifyContent: 'center',
      margin: '0 auto',
      '.content': {
        background: '#fff',
        borderRadius: [0, '8px'],
        m: ['1rem 0', '10px'],
        flex: '1',
        '.title': {
          padding: ['1rem', '15px'],
          color: '#000417',
          fontSize: ['0.8rem', '16px'],
          fontWeight: '800',
          lineHeight: ['1.6rem', '22px'],
          '.tag': {
            borderRadius: ['0.2rem', '2px'],
            color: '#fff',
            padding: ['0.2rem 0.8rem', '2px 16px'],
            mr: '10px',
            fontWeight: '400',
          },
        },
        '.from': {
          background: 'linear-gradient(90deg, #D6E8FF 0%, #F5FAFF 100%)',
          '.tag': {
            background: '#000',
          },
        },
        '.to': {
          background: 'linear-gradient(90deg, #CFF9EF 0%, #F0FFFB 100%)',
          '.tag': {
            background: '#00C479',
          },
        },
        '.product': {
          display: 'flex',
          justifyContent: 'space-between',
          '.btn': {
            cursor: 'pointer',
            color: '#005EDF',
            fontWeight: '500',
            fontSize: '14px',
          },
        },
        '.body': {
          padding: '20px',
          '.result': {
            borderRadius: '6px',
            background: 'rgba(255, 83, 0, 0.10)',
            padding: '20px',
            color: '#5B5C73',
            fontSize: '14px',
            '.first': {
              fontSize: '24px',
            },
            '.num': {
              color: '#FF5300',
              fontWeight: '600',
              mr: '10px',
            },
          },
          '.title': {
            fontSize: '16px',
            fontWeight: '800',
            lineHeight: '22px',
            pl: '0',
          },
          '.terms': {
            '.detail': {
              mb: '10px',
              fontSize: '12px',
              lineHeight: '20px',
              '.orange': {
                color: '#FF5300',
              },
              '.detail_title': {
                fontWeight: '800',
                fontSize: '14px',
                lineHeight: '22px',
              },
            },
          },
        },
      },
    },
  }
}

export default (props) => {
  const { } = useTranslation()
  const theme = useTheme()
  const [form] = Form.useForm()
  const { direction } = theme
  const { t, languages, changeLanguage, language } = useI18next()
  const [totalFee, setTotalFee] = useState(0)
  const [adValoremFee, setAdValoremFee] = useState(0)
  const [consigneeZipCodeList, setConsigneeZipCodeList] = useState([])
  const [consignorZipCodeList, setConsignorZipCodeList] = useState([])
  const [consignorProvince, setConsignorProvince] = useState('')
  const [consigneeProvince, setConsigneeProvince] = useState('')
  const { onOpen, onClose, visible } = useModalState()
  const selectModalState = useModalState()
  const pageLocale = JSON.parse(props.data.locales.edges[0].node.data)
  const footerLocale = JSON.parse(props.data.footerLocale.data)
  const seoLocale = JSON.parse(props.data.seoLocale.data)
  const isPc = useMediaQuery('@media (min-width: 800px)')
  const isLess600 = useMediaQuery('@media (min-width: 600px)')
  const { country } = useLanguageByIp()

  let length,
    width,
    height = 0

  const 长宽高rules = [
    {
      pattern: /^(^0\.[1-9][0-9]?$)|(^100$)|^([1-9]|[1-9][0-9])(\.\d{1,2})?$/,
      message: footerLocale.please_input_correct_number,
    },
  ]

  // 获取对应邮编 = 手机区号下拉
  const APIqueryZipCode = async (data) => {
    const url = `/hermes/zipCode/manage/listLikeZipCode?countryName=${data.countryName}&zipCode=${data.zipCode}`
    // const url = `${devProxyWebsite}/hermes/zipCode/manage/listLikeZipCode?countryName=${data.countryName}&zipCode=${data.zipCode}`
    return window
      .fetch(url, {
        method: 'get',
        headers: {
          lang: Cookies.get('EXPRESS_LANG'),
        },
      })
      .then((res) => {
        return res.json()
      })
      .catch((err) => {
        return err
      })
  }

  // 获取报价
  const APIgetEnquirePrice = async (data: string) => {
    const url = `/waukeen/api/price/reference`
    // const url = `${devProxyWebsite}/waukeen/api/price/reference`
    // const url = `${testproxy}/waukeen/api/price/reference`
    return window
      .fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', lang: Cookies.get('EXPRESS_LANG') },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data),
      })
      .then((res) => {
        return res.json()
      })
      .catch((err) => {
        return err
      })
  }

  const getConsigneeZipCodeList = useRequest(APIqueryZipCode, {
    manual: true,
    onSuccess: (res) => {
      console.log(1, res)
      if (res.status === 'success') {
        const list =
          res.resultObject.map((i) => ({ ...i, label: `${i.cityName}|${i.zipCode}` })) || []
        setConsigneeZipCodeList(list)
        if (!isPc) {
          selectModalState.setData((_) => ({
            ..._,
            currentItem: {
              ..._.currentItem,
              list: list,
              onSelect: (e) => {
                const obj = list.filter((_: any) => _.zipCode === e)?.[0]
                form.setFieldsValue({ consigneeCity: obj.cityName })
                form.setFieldsValue({ consigneeZipCode: e })
                setConsigneeProvince(obj.provinceName)
              },
            },
          }))
        }
      } else {
        message.error(res?.message)
      }
    },
  })

  const getConsignorZipCodeList = useRequest(APIqueryZipCode, {
    manual: true,
    onSuccess: (res) => {
      console.log(1, res)
      if (res.status === 'success') {
        const list =
          res.resultObject.map((i) => ({ ...i, label: `${i.cityName}|${i.zipCode}` })) || []
        setConsignorZipCodeList(list)
        if (!isPc) {
          selectModalState.setData((_) => ({
            ..._,
            currentItem: {
              ..._.currentItem,
              list: list,
              onSelect: (e) => {
                const obj = list.filter((_: any) => _.zipCode === e)?.[0]
                form.setFieldsValue({ consignorCity: obj.cityName })
                form.setFieldsValue({ consignorZipCode: e })
                setConsignorProvince(obj.provinceName)
              },
            },
          }))
        }
      } else {
        message.error(res?.message)
      }
    },
  })

  const submitRes = useRequest(APIgetEnquirePrice, {
    manual: true,
    onSuccess: (res) => {
      console.log(1, res)
      if (res.status === 'success') {
        console.log(res)
        let total = 0
          ; (res.resultObject?.details || []).map((i) => {
            total = total + Number(i.value)
            if (i.feeType === 'AdValoremFee') {
              setAdValoremFee(i.value)
            }
          })
        setTotalFee(total)
      } else {
        message.error(res?.message)
      }
    },
  })

  const beforeContentConsignor = (
    <Box
      sx={{
        padding: '0 15px',
        input: {
          width: '100%',
          height: '48px',
          borderRadius: '6px',
          background: '#F6F8FB',
          border: 0,
          mt: '10px',
        },
      }}
    >
      <Input
        onChange={(e) =>
          getConsignorZipCodeList.run({
            countryName: 'BRA',
            zipCode: e.target.value,
          })
        }
        placeholder='Consignor ZipCode'
      />
    </Box>
  )
  const beforeContentConsignee = (
    <Box
      sx={{
        padding: '0 15px',
        input: {
          width: '100%',
          height: '48px',
          borderRadius: '6px',
          background: '#F6F8FB',
          border: 0,
          mt: '10px',
        },
      }}
    >
      <Input
        onChange={(e) =>
          getConsigneeZipCodeList.run({
            countryName: 'BRA',
            zipCode: e.target.value,
          })
        }
        placeholder='Consignee ZipCode'
      />
    </Box>
  )

  const formListShipping: FormItemProps[] = [
    {
      name: 'consignorCountry',
      label: pageLocale.发件国家,
      ColProps: { md: 24, xs: 24 },
      children: (
        <Box
          sx={{
            ...ptBoxInFormItemsx,
            color: '#000',
            fontWeight: '600',
            img: {
              width: '36px',
              mr: '10px',
              mt: '-4px',
            },
          }}
        >
          <img src='/images/bra.svg' />
          BRA
        </Box>
      ),
    },
    isPc
      ? {
        type: 'Select',
        name: 'consignorZipCode',
        label: pageLocale.邮编,
        placeholder: pageLocale.Please_choose,
        required: true,
        ColProps: { md: 12, xs: 24 },
        data: consignorZipCodeList,
        optionValue: 'zipCode',
        optionLabel: 'label',
        SelectProps: {
          showSearch: true,
          onSearch: (v) => {
            console.log(v)
            v &&
              getConsignorZipCodeList.run({
                countryName: 'BRA',
                zipCode: v,
              })
          },
          onSelect: (e) => {
            const obj = consignorZipCodeList.filter((_: any) => _.zipCode === e)?.[0]
            form.setFieldsValue({ consignorCity: obj.cityName })
            setConsignorProvince(obj.provinceName)
          },
        },
      }
      : {
        name: 'consignorZipCode',
        label: pageLocale.邮编,
        required: true,
        ColProps: { md: 12, xs: 24 },
        children: (
          <Box
            className='ptBoxInFormItem' // 加此样式为了 表单校验错误边框显红
            sx={{
              ...ptBoxInFormItemsx,
              color: form.getFieldValue('consignorZipCode') ? '#151741' : '#d9d9d9',
            }}
            onClick={() =>
              selectModalState.onOpen({
                title: pageLocale.邮编,
                list: consignorZipCodeList,
                value: form.getFieldValue('consignorZipCode'),
                optionValue: 'zipCode',
                optionLabel: 'label',
                beforeContent: beforeContentConsignor,
                onSelect: (e) => {
                  const obj = consignorZipCodeList.filter((_: any) => _.zipCode === e)?.[0]
                  form.setFieldsValue({ consignorCity: obj.cityName })
                  form.setFieldsValue({ consignorZipCode: e })
                  setConsignorProvince(obj.provinceName)
                },
              })
            }
          >
            {form.getFieldValue('consignorZipCode')
              ? consignorZipCodeList?.filter(
                (_) => _.zipCode === form.getFieldValue('consignorZipCode'),
              )?.[0]?.zipCode
              : footerLocale.Please_choose}
          </Box>
        ),
      },
    {
      type: 'Input',
      name: 'consignorCity',
      label: pageLocale.城市,
      placeholder: ' ',
      required: true,
      disabled: true,
      ColProps: { md: 12, xs: 24 },
    },
  ].filter(Boolean)

  const formListReceiving: FormItemProps[] = [
    {
      name: 'consigneeCountry',
      label: pageLocale.到达国家,
      ColProps: { md: 24, xs: 24 },
      children: (
        <Box
          sx={{
            ...ptBoxInFormItemsx,
            color: '#000',
            fontWeight: '600',
            img: {
              width: '36px',
              mr: '10px',
              mt: '-4px',
            },
          }}
        >
          <img src='/images/bra.svg' />
          BRA
        </Box>
      ),
    },
    isPc
      ? {
        type: 'Select',
        name: 'consigneeZipCode',
        label: pageLocale.邮编,
        placeholder: pageLocale.Please_choose,
        required: true,
        ColProps: { md: 12, xs: 24 },
        data: consigneeZipCodeList,
        optionValue: 'zipCode',
        optionLabel: 'label',
        SelectProps: {
          showSearch: true,
          onSearch: (v) => {
            console.log(v)
            v &&
              getConsigneeZipCodeList.run({
                countryName: 'BRA',
                zipCode: v,
              })
          },
          onSelect: (e) => {
            const obj = consigneeZipCodeList.filter((_: any) => _.zipCode === e)?.[0]
            form.setFieldsValue({ consigneeCity: obj.cityName })
            setConsigneeProvince(obj.provinceName)
          },
        },
      }
      : {
        name: 'consigneeZipCode',
        label: pageLocale.邮编,
        required: true,
        ColProps: { md: 12, xs: 24 },
        children: (
          <Box
            className='ptBoxInFormItem' // 加此样式为了 表单校验错误边框显红
            sx={{
              ...ptBoxInFormItemsx,
              color: form.getFieldValue('consigneeZipCode') ? '#151741' : '#d9d9d9',
            }}
            onClick={() =>
              selectModalState.onOpen({
                title: pageLocale.邮编,
                list: consigneeZipCodeList,
                value: form.getFieldValue('consigneeZipCode'),
                optionValue: 'zipCode',
                optionLabel: 'label',
                beforeContent: beforeContentConsignee,
                onSelect: (e) => {
                  const obj = consigneeZipCodeList.filter((_: any) => _.zipCode === e)?.[0]
                  form.setFieldsValue({ consigneeCity: obj.cityName })
                  form.setFieldsValue({ consigneeZipCode: e })
                  setConsigneeProvince(obj.provinceName)
                },
              })
            }
          >
            {/* console.log(consigneeZipCodeList, form.getFieldValue('consigneeZipCode')) */}
            {form.getFieldValue('consigneeZipCode')
              ? consigneeZipCodeList?.filter(
                (_) => _.zipCode === form.getFieldValue('consigneeZipCode'),
              )?.[0]?.zipCode
              : footerLocale.Please_choose}
          </Box>
        ),
      },
    {
      type: 'Input',
      name: 'consigneeCity',
      label: pageLocale.城市,
      placeholder: ' ',
      required: true,
      disabled: true,
      ColProps: { md: 12, xs: 24 },
    },
  ].filter(Boolean)

  const formListProduct: FormItemProps[] = [
    {
      type: 'Input',
      name: 'weight',
      placeholder: '0.1-30',
      label: pageLocale.重量,
      required: true,
      rules: [
        {
          pattern: /^(^0\.[1-9][0-9]?$)|(^30$)|^([1-9]|1\d|2[0-9])(\.\d{1,2})?$/,
          message: footerLocale.please_input_correct_number,
        },
      ],
      ColProps: { md: 12, xs: 24 },
      InputProps: {
        addonAfter: <Box sx={{ color: '#5B5C73' }}>KG</Box>,
      },
    },
    {
      placeholder: pageLocale.Please_enter,
      label: pageLocale.体积,
      required: true,
      ColProps: { md: 12, xs: 24 },
      children: (
        <Box>
          <Input.Group size='large'>
            <Row gutter={16}>
              <Col span={8}>
                <FormItem
                  name='长'
                  label=''
                  placeholder='L'
                  type='Input'
                  required
                  rules={长宽高rules}
                  onChange={(e) => (length = Number(e.target.value))}
                  InputProps={{ addonAfter: <Box sx={{ color: '#5B5C73' }}>CM</Box> }}
                />
                {/* <Input
                  placeholder='L'
                  onChange={(e) => (length = Number(e.target.value))}
                  addonAfter={<Box sx={{ color: '#5B5C73' }}>CM</Box>}
                /> */}
              </Col>
              <Col span={8}>
                <FormItem
                  name='宽'
                  label=''
                  placeholder='W'
                  type='Input'
                  required
                  rules={长宽高rules}
                  onChange={(e) => (width = Number(e.target.value))}
                  InputProps={{ addonAfter: <Box sx={{ color: '#5B5C73' }}>CM</Box> }}
                />
                {/* <Input
                  placeholder='W'
                  onChange={(e) => (width = Number(e.target.value))}
                  addonAfter={<Box sx={{ color: '#5B5C73' }}>CM</Box>}
                /> */}
              </Col>
              <Col span={8}>
                <FormItem
                  name='高'
                  label=''
                  placeholder='W'
                  type='Input'
                  required
                  rules={长宽高rules}
                  onChange={(e) => (height = Number(e.target.value))}
                  InputProps={{ addonAfter: <Box sx={{ color: '#5B5C73' }}>CM</Box> }}
                />
                {/* <Input
                  placeholder='H'
                  onChange={(e) => (height = Number(e.target.value))}
                  addonAfter={<Box sx={{ color: '#5B5C73' }}>CM</Box>}
                /> */}
              </Col>
            </Row>
          </Input.Group>
        </Box>
      ),
    },
    {
      type: 'Input',
      name: 'declaredValue',
      placeholder: pageLocale.Please_enter,
      label: pageLocale.货值,
      rules: [
        {
          pattern: /(^([1-9]\d*$)|(\d+(\\.\d+)?$))/,
          message: footerLocale.please_input_correct_number,
        },
      ],
      required: true,
      ColProps: { md: 12, xs: 24 },
      InputProps: {
        addonAfter: <Box sx={{ color: '#5B5C73' }}>BRL</Box>,
      },
    },
    {
      type: 'RadioGroups',
      name: 'isIssurance',
      label: pageLocale.是否添加保险,
      data: [
        {
          label: 'YES',
          value: true,
        },
        {
          label: 'NO',
          value: false,
        },
      ],
      radioGroupProps: {
        className: 'radio-group',
      },
    },
  ].filter(Boolean)

  const handleSubmit = () => {
    console.log('提交')
    form
      .validateFields()
      .then((values) => {
        values.consigneeCountry = 'BRA'
        values.consignorCountry = 'BRA'
        console.log(length, width, height)
        values.volumeWeight = length * width * height
        console.log(values)
        delete values.长
        delete values.宽
        delete values.高
        submitRes.run({
          ...values,
          consigneeProvince,
          consignorProvince,
          declaredValue: Number(values.declaredValue),
        })
        // logEvent('点击_首页_咨询问价提交')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <SEO
        title={seoLocale.homepage.title}
        description={seoLocale.homepage.description}
        pageLocale={pageLocale}
      />
      <Helmet>
        <meta
          name='keywords'
          content='last mile delivery, ecommerce shipping solutions, pickup and delivery service, ecommerce delivery service, last mile logistics solutions'
        />

        <meta property='og:type' content='website' />
        <meta property='og:site_name' content='iMile Delivery' />
        <meta property='og:url' content='https://www.imile.com/' />
        <meta
          property='og:image'
          content='https://www.imile.com/static/6012dd844d0fcc97f9cd36f8393c6951/334d0/home2_bg.webp'
        />
        <meta property='og:title' content='Logistics and Last-mile Delivery Solutions' />
        <meta
          property='og:description'
          content='Reliable and efficient eCommerce delivery solutions. iMile uses smart logistics technology to offer unparalleled efficiencies throughout the supply chain.'
        />
      </Helmet>
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
      <Box sx={{ ...styleRoot() }}>
        <Box className='pageTitle'>
          {isPc && <Box className='line'>——</Box>}
          {!isPc && (
            <Fanhuishangye
              sx={{ mt: '0.5rem', mr: '1rem', ml: '1rem' }}
              onClick={() => history.go(-1)}
            />
          )}
          {pageLocale.自主测算报价}
          {isPc && <Box className='line'>——</Box>}
        </Box>
        <Box className='pageBody'>
          <GlobalAntdFormStyle />
          <Form labelCol={{ span: 24 }} form={form} name='form_in_modal'>
            <Box className='pageBody'>
              <Box className='content'>
                <Box className='title from'>
                  <Box className='tag' component='span'>
                    {pageLocale.发送}
                  </Box>
                  {pageLocale.发件城市}
                </Box>
                <Box className='body'>
                  <Row gutter={20}>
                    {formListShipping.map((_) => (
                      <FormItem {..._} key={_.name} />
                    ))}
                  </Row>
                </Box>
              </Box>
              <Box className='content'>
                <Box className='title to'>
                  <Box className='tag' component='span'>
                    {pageLocale.到达}
                  </Box>
                  {pageLocale.收件城市}
                </Box>
                <Box className='body'>
                  <Row gutter={20}>
                    {formListReceiving.map((_) => (
                      <FormItem {..._} key={_.name} />
                    ))}
                  </Row>
                </Box>
              </Box>
            </Box>
            <Box className='pageBody'>
              <Box className='content'>
                <Box className='title product' sx={{ border: '1px solid #EFEFF5' }}>
                  <Box>{pageLocale.产品规格}</Box>
                  <Box className='btn' onClick={() => window.open('/prohibited-parcels')}>
                    {pageLocale.禁用产品清单}
                  </Box>
                </Box>
                <Box className='body'>
                  <Row gutter={20}>
                    {formListProduct.map((_) => (
                      <FormItem {..._} key={_.name} />
                    ))}
                  </Row>
                  <Box
                    sx={{
                      textAlign: 'right',
                      button: {
                        borderRadius: '6px',
                        background: '#005EDF',
                        padding: '14px 40px',
                        height: 'auto',
                      },
                    }}
                  >
                    <Button key='2' type='primary' loading={false} onClick={handleSubmit}>
                      {pageLocale.获取报价}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className='pageBody'>
              <Box className='content'>
                <Box className='body'>
                  <Box className='result'>
                    <Box>
                      <Box className='first num' component='span'>
                        {totalFee}
                      </Box>
                      BRL
                    </Box>
                    <Box className='second'>
                      {pageLocale.含保费}：
                      <Box className='num' component='span'>
                        {adValoremFee}
                      </Box>
                      BRL
                    </Box>
                  </Box>

                  <Box className='terms'>
                    <Box className='title'>{pageLocale.服务条款}</Box>
                    {pageLocale.服务条款明细.map((i, index) => {
                      return (
                        <Box className='detail'>
                          <Box className='detail_title'>{i.title}</Box>
                          {i.content.map((c) => {
                            return (
                              <Box
                                className={
                                  index === pageLocale.服务条款明细.length - 1 ? 'orange' : ''
                                }
                              >
                                {c}
                              </Box>
                            )
                          })}
                        </Box>
                      )
                    })}
                  </Box>
                </Box>
              </Box>
              {/* {formList.map((_) => (
                <FormItem {..._} key={_.name} />
              ))} */}
            </Box>
          </Form>
        </Box>
      </Box>

      <Modal
        title={pageLocale.禁用产品清单}
        open={visible}
        onCancel={onClose}
        footer={null}
        width={'861px'}
        closeIcon={<Close4 />}
      >
        <Box>
          {pageLocale.禁用产品清单明细.map((i) => {
            return (
              <Box sx={{ mb: '16px' }}>
                <Box
                  sx={{ color: '#000417', fontSize: '14px', fontWeight: '600', lineHeight: '22px' }}
                >
                  {i.title}
                </Box>
                {i.content.map((c) => (
                  <Box
                    sx={{
                      color: '#5B5C73',
                      fontSize: '12px',
                      lineHeight: '20px',
                    }}
                  >
                    {c}
                  </Box>
                ))}
              </Box>
            )
          })}
        </Box>
      </Modal>

      <SelectModalH5
        currentItem={selectModalState.currentItem}
        visible={selectModalState.visible}
        onClose={selectModalState.onClose}
      />

      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
        notShowQuoteBtn={!isPc}
      />
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["calculations"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    commonLocale: locale(ns: { eq: "common" }, language: { eq: $language }) {
      data
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`
